@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes slideIn {
    from {
        opacity: 0;
        /*transform: translateY(-20px);*/
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideOut {
    from {
        opacity: 1;
        transform: translateY(0);
    }
    to {
        opacity: 0;
        /*transform: translateY(-20px);*/
    }
}

.animate-slideIn {
    animation: slideIn 0.3s ease-out forwards;
}

.animate-slideOut {
    animation: slideOut 0.3s ease-in forwards;
}

@keyframes slideDown {
    from { transform: translateY(-100%); }
    to { transform: translateY(0); }
}

.slide-down {
    animation: slideDown 0.5s ease-out;
}

.wrapper {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}

.truncate-label {
    white-space: nowrap;
    overflow: hidden;
    max-width: 13rem;
    text-overflow: ellipsis;
}

/* on mobile max-width: 8 rem */
@media only screen and (max-width: 767px) {
    .truncate-label {
        max-width: 10rem;
    }
}

.group:hover .group-hover\:block {
    display: block;
}

/* Dans un fichier CSS */
.date-input {
    border: 1px solid #ccc;
    border-radius: 4px;
    display: inline-flex;
    padding: 2px;
}

.tab-btn {
    @apply px-4 py-2;
}

.tab-active {
    @apply border-b-2 border-secondary-color font-bold;
}

.tab-disabled {
    color: #ccc;
    cursor: not-allowed;
}

.date-part {
    border: none;
    padding-left: 0.5rem;
    width: 4rem;
    text-align: center;
}

@media (min-width: 750){
    .date-part {
        border: none;
        padding-left: 0.5rem;
        width: 2rem;
        text-align: center;
    }
}

@media only screen and (orientation: landscape) and (hover: none) {
    body:focus-within,
    body:focus-within .an-absolutely-positioned-input-ancestor {
        max-height: 53vh;
        overflow: auto;
    }
}

body {
    font-family: "Inter", sans-serif;
    font-weight: 300;
    line-height: 1.2;
    background: linear-gradient(rgb(0,0,0,1%), rgb(0,0,0,8%));
    color: #1F2937;
}

.Toastify__toast-container{
    font-weight: 500 !important;
}

.copy {
    position: absolute;
    left: -9999px;
    -webkit-user-modify: read-only;
}

.grid-container {
    display: grid;
    grid-template-columns: 1fr; /* Side nav is hidden on mobile */
    grid-template-rows: 52px 1fr 50px;
    grid-template-areas:
      'header'
      'main'
      'footer';
    height: 100vh;
}

.menu-icon {
    position: fixed;
    display: flex;
    top: 50%;
    left: 0;
    z-index: 1000;
    cursor: pointer;
}

.menu-clic-space{
    display: block;
}
/* Mobile-first side nav styles */
.sidenav {
    grid-area: sidenav;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    position: fixed;
    overflow-y: auto;
    z-index: 90; /* Needs to sit above the hamburger menu icon */
    transform: translateX(-100%);
    transition: all .4s ease-in-out;
}


/* The active class is toggled on hamburger and close icon clicks */
.sidenav.active {
    transform: translateX(0);
}

/* Only visible on mobile screens */
.sidenav__close-icon {
    visibility: visible;
    right: 5px;
    cursor: pointer;
    font-size: 25px;
    color: #ddd;
}
.logo {
    width: 14rem;
}
/* Non-mobile styles for side nav responsiveness, 750px breakpoint */
@media only screen and (min-width: 78.125em) {
    .sidenav {
        position: relative; /* Fixed position on mobile */
        transform: translateX(0);
    }

    .sidenav__close-icon {
        visibility: hidden;
    }

    .menu-clic-space{
        display: none;
    }
}

/* Non-mobile styles, 750px breakpoint */
@media only screen and (min-width: 78.125em) {
    /* Show the sidenav */
    .grid-container {
        grid-template-columns: 240px 1fr;
        grid-template-rows: 46px 1fr 50px; /* Show the side nav for non-mobile screens */
        grid-template-areas:
        "sidenav header"
        "sidenav main"
        "sidenav footer";
    }

    .sidenav {
        display: flex;
        flex-direction: column;
    }
}


/*.main-cards {*/
/*    column-count: 1;*/
/*    column-gap: 20px;*/
/*}*/

.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: #82bef6;
    margin-bottom: 20px;
    -webkit-column-break-inside: avoid;
    padding: 24px;
    box-sizing: border-box;
}

/*@media only screen and (min-width:78.125em) {*/
/*    .main-cards {*/
/*        column-count: 2;*/
/*        column-gap: 20px;*/
/*    }*/
/*}*/

.bg-color-light-gray {
    background: #F3F4F5;
}

.bg-color-dark-green {
    background: #114049;
}

.bg-color-light-dark-green {
    background: #185b6a;
}

.bg-color-light-darker-green {
    background: #15515e;
}

.bg-color-light-blue-green {
    background: #D9E6E9;
    font-family: "Public Sans", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    color: #0e353d;
}

.home-title {
    font-family: "GT Walsheim Pro", Helvetica, Arial, sans-serif;
}

.custom-max-height {
    max-height: 63vh;
    min-height: 63vh;
}

.custom-parameter-height {
    max-height: 86vh;
    min-height: 86vh;
}

.custom-preferences-height {
    max-height: 90vh;
    min-height: 90vh;
}

.custom-parameter-config-height{
    max-height: 80vh;
    min-height: 80vh;
}

@media (max-width: 639px)   {
    .custom-parameter-config-height {
        max-height: 75vh;
        min-height: 75vh;
    }
}
.custom-max-height-mobile {
    max-height: 90vh;
    min-height: 80vh;
}

.custom-max-height-mobile-reporting {
    max-height: 75vh;
}

@media (min-width: 1024px)   {
    .custom-max-height-mobile-reporting {
        max-height: 90vh;
    }
}

.custom-height-mobile {
    max-height: 90vh;
    min-height: 75vh;
}

.custom-planning-height {
    max-height: 95vh;
    min-height: 63vh;
}

.custom-configurator-height {
    max-height: 70vh;
    min-height: 60vh;
}

@media (min-width: 992px)   {
    .custom-configurator-height {
        max-height: 80vh;
    }
}
.custom-min-max-h {
    min-height: 10vh;
    max-height: 10vh;
}

.p-date-picker-button {
    padding-top: 1px;
    padding-bottom: 1px;
}

.w-59 {
    width: 59%;
}

.px-picker {
    padding-left: 39.98px;
    padding-right: 39.98px;
}

.select__control{
    font-size: 1.3rem;
    padding-top: 6px;
    padding-bottom: 6px;
}

.select__indicator > svg {
    height: 30px;
    width: 30px;
}

@media (min-width: 750px) {
    .select__control {
        font-size: 1rem;
        padding-top: 1px;
        padding-bottom: 1px;
    }
    .select__indicator > svg {
        height: 20px;
        width: 20px;
    }


}


.select__placeholder {
    opacity: 0.3;
    margin: 0;
    padding: 0;
}

div.ap-footer {
    display: none;
}

.ap-input {
    border-color: rgb(226, 232, 240);
    border-bottom-width: 2px;
    border-right-width: 0px;
    border-left-width: 0px;
    border-top-width: 0px;
    border-radius: 0px;
}


.algolia-places-nostyle {
    width: 100%;
}

.planning-title-grid {
    grid-template-columns: 25% 15% 10% 15% 15% 5% 15%;
}

.planning-second-title-grid {
    grid-template-columns: 20% 30% 40%;
}

.movable-item-grid {
    grid-template-columns: 2% 23% 23% 23% 23% 3%;
}

.planning-column-names-grid {
    grid-template-columns: 23% 22% 23% 22% 10%;
}

.sorted-run-car-grid {
    grid-template-columns: 85% 15%;
}

.sorted-pc-run-car-grid {
    grid-template-columns: 90% 10%;
}

.max-h-64 {
    max-height: 16rem;
}

@media (max-width: 1279px) {
    .max-h-64 {
        max-height: 11.7rem;
    }
}

.top {
    top: 37% !important;
    left: -2% !important;
}

.generation {
    margin-left: -80px;
}

.max-h-60 {
    height: 15rem;
}

.h-60 {
    height: 16rem;
}

.min-height-52 {
    min-height: 13rem;
}

.min-height-30-rem {
    min-height: 30rem;
}

.min-height-70 {
    min-height: 70vh;
}
.min-height-60 {
    min-height: 64vh;
}


.min-height-90 {
    min-height: 80vh;
    max-height: 80vh;
}

.min-height-90-x {
    min-height: 90vh;
}

.min-height-x {
    max-height: 95vh;
    min-height: 40vh;
}

.min-height-global {
    max-height: 95vh;
    min-height: 40vh;
}
/*@media only screen and (min-width: 1024px) {*/
/*    .min-height-x {*/
/*        max-height: 80vh;*/
/*        min-height: 80vh;*/
/*    }*/
/*}*/

/*@media only screen and (min-height: 900px) {*/
/*    .min-height-x {*/
/*        max-height: 90vh;*/
/*    }*/
/*}*/


/*PAGINATION*/

.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

}

.pagination > li > a {
    padding: 15px;
}

.pagination > li > a {
    width: 12px;
    height: 12px;
}

.pagination__link {
    padding: 10px;
}

.pagination__link--active {
    font-weight: bold;
    border: 1px grey solid;
    padding: 2px 4px;
    border-radius: 30px;
}

.pagination-grid {
    grid-template-columns: 60% 40%;
}

/*tr:nth-child(even) {background: #fcfcfc*/
/*}*/
/*tr:nth-child(odd) {background: #ffffff*/
/*}*/


tr.disabled {
    background-color: #E2E8F0;
}

tr.disabled > td > div {
    color: #82858d;
}

tr.disabled > td > span {
    background: #E2E8F0;
}

tr.disabled > td > span {
    color: #565a5e;
}

.right-15 {
    right: 2vh;
}

.scale-1 {
    scale: 1.1;
}

.scale-1-5 {
    scale: 1.5;
}
@media only screen and (max-width: 1023px) {
    .scale-1-5 {
        scale: 2.5;
    }
}


.negative-margin-15 {
    margin-left: -15vh;
}


.custom-min-w-address {
    min-width: 10rem;
    max-width: 10rem;
}

.custom-min-w-icon-address {
    min-width: 3rem;
    max-width: 3rem;
}

.custom-max-manage-run-height {
    max-height: 65vh;
}

.options {
    visibility: hidden;
    opacity: 0;
    transition: opacity 250ms ease-in, visibility 0ms ease-in 250ms;
}


.parents:hover .options{
    opacity: 0.8;
    transition: opacity 250ms ease-in, visibility 0ms ease-in 0ms;
    visibility: visible;
}


.h-96 {
    height: 24rem;
}

.invisible-scrollbar::-webkit-scrollbar {
    width: 0px;
    scrollbar-gutter: stable;
}

.invisible-scrollbar::-webkit-scrollbar-thumb {
    background: #666;
    border-radius: 20px;
    scrollbar-gutter: stable;
}

.h-18rem{
    height: 18rem;
}


.max-h-96 {
    max-height: 24rem;
}

.series-max-height {
    max-height: 22rem;
}

.commentary-width {
    width: 67%;
}

@media only screen and (max-width: 772px) {
    .commentary-width {
        width: 83%;
    }
}

@media only screen and (min-width: 772px) {
    .add-patient-button {
        position: absolute;
        margin-left: 33%;
        margin-top: 1%;
    }
}


.shadow-mobile {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.shadow-transferable {
    box-shadow: rgb(50, 180, 9) 0px 1px 6px 0px;
}

.shadow-mobile-return-card {
    -webkit-box-shadow: 6px 2px 14px 4px #525252;
    box-shadow: 3px 2px 6px 0 #646464;
}

.shadow-mobile-aller-card {
    -webkit-box-shadow: 6px 2px 14px 4px #8d8d8d;
    box-shadow: 3px 2px 6px 0 #8d8d8d;
}

.bg-mobile {
    background-color: #ECECEC69;
}

.mobile-header{
  height: 47px;
    padding-right: 4px;
}

.w-28 {
    width: 7rem;
}

.pr-1-7 {
    padding-right: 1.7rem;
}

.popup {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0ms ease-in, visibility 0ms ease-in 250ms;
}

.popup.show {
    visibility: visible;
    opacity: 1;
    transition: opacity 250ms ease-in, visibility 0ms ease-in 0ms;
}


.popup.true  {
    visibility: visible;
    opacity: 1;
    transition: opacity 250ms ease-in, visibility 0ms ease-in 0ms;
}

.h-88-vh {
    height: 88vh;
}


.max-w-type-select {
    max-width: 18rem;
}

.max-w-label-select {
    max-width: 21vh;
}

.max-w-days-select {
    max-width: 25rem;
}

.min-w-date-input{
    min-width: 10rem;
    max-width: 10rem;
}

.min-w-select-types{
    min-width: 12rem;
    max-width: 12rem;
}


@media only screen and (max-width: 1024px) {
    .min-w-select-types{
        max-width: initial;
        min-width: 3rem;
    }
}


.min-h-row-date{
    min-height: 5rem;
}



.max-w-date{
    max-width: 8rem;
    min-width: 7rem;
}

.max-w-hours{
    max-width: 8rem;
    min-width: 3rem;
}


.manage-max-w-hours{
    max-width: 8rem;
    min-width: 3rem;

}

@media only screen and (max-width: 1024px) {
    .manage-max-w-hours{
        max-width: max-content;
        min-width: 3rem;
    }
}

.planning-run-card-grid-3 {
    grid-template-columns: 4% 88% 8%;
}

.planning-grid-3 {
    grid-template-columns: 5% 90% 5%;
}

/*Color picker*/

.twitter-picker div:last-child div:nth-last-child(2){
    display: none !important;
}

.twitter-picker div:last-child div:nth-last-child(3){
    display: none !important;
}

.fa-rotate-137{
    transform: rotate(137deg)
}

.left-45-percent{
    left:45%;
    padding-top:3.5rem;
}

@media only screen and (min-width: 1249px) {
    .left-45-percent{
        left:59%;
        padding-top:3.5rem;
    }
}

.min-w-8{
    min-width: 8vh !important;
}

.min-w-2-5{
    min-width: 2.5rem;
}

.min-w-12{
    min-width: 12rem;
}

.w-select-user{
    width: 25rem;
}


.h-graph{
   height: 80vh;
}

.stripeBg{
    background-image: linear-gradient(45deg, #ffffff 25%, #efefef 25%, #efefef 50%, #ffffff 50%, #ffffff 75%, #efefef 75%, #efefef 100%);
    background-size: 56.57px 56.57px;
}

.stripeBgMin{
    background-image: linear-gradient(45deg, #ffffff 25%, #efefef 25%, #efefef 50%, #ffffff 50%, #ffffff 75%, #efefef 75%, #efefef 100%);
    background-size: 10px 10px;
}

.stripeBg_canceled{
    background-image: linear-gradient(45deg, #ffffff 25%, rgba(255, 224, 197, 0.7) 25%, rgba(255, 224, 197, 0.7) 50%, #ffffff 50%, #ffffff 75%, rgba(255, 224, 197, 0.7) 75%, rgba(255, 224, 197, 0.7) 100%);
    background-size: 56.57px 56.57px;
}

.pb-47px {
    padding-bottom: 41px;
}

.stripeBg{
    background-image: linear-gradient(45deg, #ffffff 25%, #efefef 25%, #efefef 50%, #ffffff 50%, #ffffff 75%, #efefef 75%, #efefef 100%);
    background-size: 56.57px 56.57px;
}

.stripeBgMin{
    background-image: linear-gradient(45deg, #ffffff 25%, #efefef 25%, #efefef 50%, #ffffff 50%, #ffffff 75%, #efefef 75%, #efefef 100%);
    background-size: 10px 10px;
}

.stripeBg_canceled{
    background-image: linear-gradient(45deg, #ffffff 25%, rgba(255, 224, 197, 0.7) 25%, rgba(255, 224, 197, 0.7) 50%, #ffffff 50%, #ffffff 75%, rgba(255, 224, 197, 0.7) 75%, rgba(255, 224, 197, 0.7) 100%);
    background-size: 56.57px 56.57px;
}

.pb-47px{
    padding-bottom: 41px;
}

@media only screen and (min-width: 1280px) {
    .min-h-plan{
        min-height: 62vh;
        max-height: 62vh;
    }

    .min-h-plan-infos{
        min-height: 45vh;
    }

    .w-plan-card{
        min-width: 26rem;
        max-width: 26rem;
    }
}

.w-signup-input{
    width:24rem;
}

.w-tunnel-button{
    width: 20rem;
}

.logo-svg{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

@media only screen and (max-width: 880px) {
    .logo-svg{
        left: 90%;
    }
}

.button-plan-md-width{
    width:34vh;
}

.Kalend__header_calendar_buttons__container{
        display: none;
}

.w-location-inputs{
    width:91%;
}

@media only screen and (min-width: 768px) {
    .w-location-inputs{
        width:73%;
    }
}

.min-w-14{
    min-width: 14rem;
}

.min-w-17vh{
    min-width: 17vh;
}

.rdrCalendarWrapper {
    color: #000000;
    font-size: 12px !important;
}

@media only screen and (max-width: 767px) {
    .rdrCalendarWrapper {
        font-size: 16px !important;
    }
}

@media only screen and (max-width: 767px) {
    .calendar-negative-margin{
        margin-top: -30vh;
    }
}

.Kalend__CalendarHeader-tall-day{
    display: none;
}

.max-h-calendar{
    max-height: 100vh;
}

@media only screen and (min-width: 1024px) {
    .w-fit{
        width: fit-content;
    }
}


.login-shadow{-moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}



.btn-stripeBg{
    background-image: linear-gradient(45deg, #ffffff 25%, #efefef 25%, #efefef 50%, #ffffff 50%, #ffffff 75%, #efefef 75%, #efefef 100%);
    background-size: 56.57px 56.57px;
    border: solid 1px rgba(0, 0, 0, 0.43) !important;
}

.calend-shared > div > p{
    color: black !important;
}


.custom-fa-size{
    font-size:8px;
}

.min-h-pricing-card{
    min-height: 29rem;
}

.top-right-5 {
    top: 5%;
    right: 5%;
}

.w-notif{
    min-width: 300px;
    max-width: 300px;
}

.modal-shadow{
    -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
}


.border-line{
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 7.5rem;
    border-bottom-left-radius: 7.5rem;
}


.rounded-modal{
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
}

.shadow-modal {
    -webkit-box-shadow: 0px 0px 5px 10px rgba(99, 99, 99, 0.2);
    box-shadow: 0px 0px 5px 10px rgba(99, 99, 99, 0.2);
}

.modal{
    z-index: 101;
}

.min-h-modal{
    min-height: 60vh;
    max-height: 80vh;
}

.min-h-add-team-modal{
    min-height: 50vh;
    max-height: 50vh;
}

/* @media (width: 750px) {
    .min-h-modal{
        min-height: 60vh;
        max-height: 80vh;
    }
} */


.min-h-modal-mobile{
    min-height: 75vh;
    max-height: 90vh;
    max-width: 60rem;
}


.select__placeholder{
    color: #4e5757 !important;
    font-weight: normal !important;
    font-size: 16px !important;
}


.min-h-action-modal{
    min-height: 20vh;
}


.min-w-icon{
    min-width: 1.5rem;
}


input[name=form]:invalid{
    border-color: rgb(239 68 68);
}


.min-h-filter{
  min-height: 600px;
}
.min-h-config-planning-modal{
  min-height: 500px;
}

.min-h-filter-planning-modal{
  min-height: 500px;
}

.w-mobile{
    width: 390px;
    max-width: 390px;
}

.min-h-5-3{
    min-height: 5.3rem;
}
.min-h-2-7{
    min-height: 2.7rem;
}

.parameters-height{
    height: 95vh;
}

.max-w-7xl{
    max-width: 100rem;
}


.min-w-team-cards{
    min-width: 23rem;
}


.hide{
    display: none;
}

.max-other-runs-height{
    height: 28vh;
    max-height: 28vh;
}

.max-h-addresses{
    max-height: 325px;
}


/*PLANNING DASHBOARD*/

.ml-1-4rem{
    margin-left: 1.4rem;

}

.vertical-time-line{
    border-left: 1px solid red;
    height: 800vh;
}

.vertical-dashed-line{
    border-left: 1px dashed rgb(164, 164, 164, 0.6);
    height: 800vh;
    margin-left: 1.4rem;
}

.h-800vh{
    height: 900vh;
}

.linear-dashboard-gradient{
    background: linear-gradient(180deg, rgba(255, 245, 228, 0) 0%, rgba(255, 245, 228, 0.20) 31.25%);
}

.linear-dashboard-gradient-bis{
    background: linear-gradient(180deg, rgba(230, 150, 255, 0) 0%, rgba(230, 150, 255, 0.20) 31.25%);

}


.grid-planning-dashboard{
    display: grid;
    grid-template-columns: 3% 97%;
}


.fullscreen-dashboard-planning{
    z-index: 91;
}

.fullscreen-dashboard-planning-cards{
    z-index:92;
}

.z-31{
    z-index:31;
}

.z-32{
    z-index:32;
}

.z-1000{
    z-index: 1000;
}


/*END PLANNING DASHBOARD*/

.clip{
    overflow: hidden;
    text-overflow: clip;
    white-space: nowrap;
}


.choice{
    min-width: 6rem;
}


.min-h-header-invoice{
    min-height: 7rem;
}


.invoice-select{
    min-width: 12rem;
    max-width: 13rem;
}

@media only screen and (max-width: 767px) {
    .invoice-select{
        min-width: 12rem;
        max-width: none;
        width: 100%;
    }
}


.w-vat{
    min-width: 45px;
    max-width: 40px;
}

@media only screen and (max-width: 1010px) {
    .w-vat{
        width: 50%;
        min-width: auto;
        max-width: max-content;
    }
}


.w-prices{
    min-width: 90px;
    max-width: 85px;
}

@media only screen and (max-width: 1010px) {
    .w-prices{
        width: 50%;
        min-width: auto;
        max-width: max-content;
    }
}


.w-mtt{
    min-width: 90px;
    max-width: 85px;
}

@media only screen and (max-width: 1010px) {
    .w-mtt{
        width: 100%;
        min-width: auto;
        max-width: max-content;
    }
}

.min-w-annexe-modal{
    min-width: 1024px;
}

.max-w-cells{
    max-width: 12rem;
}

@media only screen and (max-width: 1010px) {
    .max-w-cells{
        max-width: 100%;
    }
}

.max-height-90{
    max-height: 90vh;
}

.min-height-90{
    min-height: 90vh;
}


.hide-scroll::-webkit-scrollbar {
    display: none;
}

.hide-scroll{
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
}



.hover-first-index:hover{
    z-index:100 !important;
    transition-delay: 0.5s;
    transition-property: z-index;
}

.h-documentation{
    height: 34rem;
}
.max-h-documentation-content{
    height: 31.70rem;
}


.text-xxxs{
    font-size: 0.5rem; /* 10px */
    line-height: 1rem;
}

.text-xxs{
    font-size: 11px;
    line-height: 1rem;
}


.text-xsm{
    font-size: 13px; /* 12px */
    line-height: 1rem; /* 16px */
}


.width-child-container-welcome-modal{
    width: 55rem;
    height: 38rem;
    max-height: 38rem;
}

@media only screen and (max-width: 78.125em){
    .width-child-container-welcome-modal{
        width: 45rem;
    }
}

@media only screen and (max-width: 50.125em){
    .width-child-container-welcome-modal{
        width: 35rem;
    }
}


@media only screen and (max-width: 38.125em){
    .width-child-container-welcome-modal{
        width: 25rem;
    }
}

.height-35-rem{
    height: 35rem;
    max-height: 35rem;
}

.height-31-rem{
    height: 31rem;
    max-height: 31rem;
}

.step{
    background: linear-gradient(to right, #FE748B 50%, #D9D9D9 50%);
    background-position: right bottom;
    transition: all 0.5s ease-out;
    background-size: 200% 100%;
}

.stepTransition{
    background-position: left bottom;
}

.h-documentation{
    height: 34rem;
}
.max-h-documentation-content{
    height: 31.70rem;
}


.text-xxxs{
    font-size: 0.5rem; /* 12px */
    line-height: 1rem;
}

.bg-transferable{
    background-color: rgba(133, 246, 114, 0.6);
}

.gradient {
    color: white;
    font-weight: 700;
    padding-left: 1rem;
    padding-right: 1rem;

    background: linear-gradient(#FFFFFF, #FFFFFF),
    linear-gradient(
        var(--gradient-rot, 120deg),
        rgb(24, 90, 105) 0%,
        rgb(254, 116, 139) 50%,
        rgb(32, 118, 138) 100%
    );
    border: 4px solid transparent;
    border-radius: 0.5rem;
    background-clip: padding-box, border-box;
    background-origin: border-box;
}

.text-notif{
    font-size: 0.85rem;
    line-height: 1.1rem;
}

.font-test{
    font-family: Inter,sans-serif !important;
}

.w-return-deposit{
    width: 46.5%;
}

@media only screen and (max-width: 1023px) {
    .w-return-deposit{
        width: 100%;
    }
}
.vital {
    background: #F7C501;
    background: linear-gradient(70deg, #F7C501 20%, rgb(0, 130, 61) 20%);
}


#card:hover { transform: rotateY(180deg); }

#card > div:nth-child(1) { transition-delay: 150ms;}

#card:hover > div:nth-child(1) {
    opacity: 0;
    z-index: -1;
}

.rotateY {
    transform: rotateY(180deg);
}

.w-names{
    width:20%;
    max-width: 20%;
}


.grid-cols-select-3{
    grid-template-rows: 20% 60% 20%;
}

.width-range-drop-down{
    width: 6.7rem;
}

.width-planning-stats{
    width: 10rem;
}

.width-add-drop-down{
    width: 7.7rem;
}

.group-card-names-width {
    width: 80%;
    min-width: 80%;
}

.grid-cols-action-grouped-runs-2{
    grid-template-columns: 93% 7%;
}

.grid-cols-options-2{
    grid-template-columns: 30% 70%;
}

.team-drag-and-drop-card-grid-5 {
    grid-template-columns: 5% 5% 40% 30% 20%;
}

.team-card-grid-5 {
    grid-template-columns: 5% 40% 30% 20% 5%;
}

.team-card-grid-6 {
    grid-template-columns: 5% 30% 30% 20% 10% 5%;
}

.team-drag-and-drop-card-grid-4 {
    grid-template-columns: 10% 10% 45% 35%;
}

.team-card-grid-4 {
    grid-template-columns: 10% 45% 35% 10%;
}

.modal-teams-height {
    max-height: 57vh;
    min-height: 57vh;
}

@media (max-height: 770px){
    .modal-teams-height {
        max-height: 40vh;
        min-height: 40vh;
    }
}


.rdrDayToday .rdrDayNumber span::after {
    content: '';
    position: absolute;
    bottom: 4px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 18px;
    height: 2px;
    font-weight: 700 !important;
    border-radius: 2px;
    background:  #8aafb0 !important;
}
